<template>
  <div id="room-detail-page">
    <section class="detail-desc-room">
      <div class="container">
        <div class="row for-rd">
          <div class="col-lg-6 col-sm-6">
            <img :src="banner" class="img-fluid w-100">
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="room-desc-detail">
              <h2 class="detail-title">{{ subject }}</h2>
              <p v-html="overview"></p>
              <div class="row ">
                <div class="col-sm-5">
                  <div class="date">Registration Date</div>
                  <div class="date-info mb24">{{ registration_date }}</div>
                </div>
                <div class="col-sm-7">
                  <div class="date">Simulation Date</div>
                  <div class="date-info mb24">{{ simulation_date }}</div>
                </div>
              </div>

              <div class="row align-items-center" v-if="$store.getters.userType == 'student'">
                <div class="col-sm-5">
                  <a href="#" @click="joinId = id" class="btn btn-orange w-100">Join</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="room-tabs">
      <div class="container">
        <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-overview-tab" data-bs-toggle="pill" data-bs-target="#pills-overview"
              type="button" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-role-tab" data-bs-toggle="pill" data-bs-target="#pills-role" type="button"
              role="tab" aria-controls="pills-role" aria-selected="false">Role-Play</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-guide-tab" data-bs-toggle="pill" data-bs-target="#pills-guide"
              type="button" role="tab" aria-controls="pills-guide" aria-selected="false">Model Guide</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active for-p" id="pills-overview" role="tabpanel"
            aria-labelledby="pills-overview-tab">
            <h4 class="more-cs mb24">Details</h4>
            <h4 class="cs-head">Situation</h4>
            <p v-html="roomDetail.the_situation"></p>
            <h4 class="cs-head">The Issue</h4>
            <p v-html="issue"></p>
            <div class="container-iframe" v-if="overview_link">
              <iframe class="responsive-iframe" :src="overview_link"></iframe>
            </div>
          </div>
          <div class="tab-pane fade for-p" id="pills-role" role="tabpanel" aria-labelledby="pills-role-tab">
            <h4 class="more-cs mb24">Role-Play</h4>
            <p></p>

            <div class="accordion" id="accordion-role">
              <template v-for="(role, index) in participants">
                <div class="accordion-item" :key="index">
                  <h2 class="accordion-header" :id="`heading-${index}`">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      :data-bs-target="`#collapse-${index}`" aria-expanded="false" :aria-controls="`collapse-${index}`">
                      {{ role.name }}
                    </button>
                  </h2>
                  <div :id="`collapse-${index}`" class="accordion-collapse collapse" :aria-labelledby="`heading-${index}`"
                    data-bs-parent="#accordion-role">
                    <div class="accordion-body">
                      <p>Description of Role:</p>

                      <p v-html="role.role_description"></p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="tab-pane fade for-p" id="pills-guide" role="tabpanel" aria-labelledby="pills-guide-tab">
            <h4 class="more-cs mb24">Simulation Guide</h4>
            <p v-html="model"></p>
            <div class="container-iframe" v-if="model_guide_link">
              <iframe class="responsive-iframe" :src="model_guide_link"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="more-case">
      <div class="container">
        <h4 class="more-cs mb40">More Cases</h4>
        <div class="row justify-content-center">
          <template v-for="(cases, index) in relateds">
            <div class="col-lg-4 col-sm-6" :key="index">
              <div class="box-cs">
                <div class="img-room">
                  <figure>
                    <router-link :to="`/room/${cases.id}`">
                      <img :src="cases.banner_url" class="img-fluid w-100">
                    </router-link>
                  </figure>
                </div>
                <div class="box-white">
                  <h4 class="cs-head">{{ cases.subject_topic }}</h4>
                  <p v-html="cases.case_overview"></p>
                  <div class="date">Registration Date</div>
                  <div class="date-info mb24">{{ cases.registration_date }}</div>

                  <div class="date">Simulation Date</div>
                  <div class="date-info mb40">{{ cases.simulation_date }}</div>

                  <router-link :to="`/room/${cases.slug}`" class="link-service">
                    View Details &nbsp; <span class="icon-chevron-right"></span>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="for-link">
          <router-link to="/room" class="link-underline">View All Cases</router-link>
        </div>
      </div>
    </section>

    <!-- popup confirm join -->
    <div class="popup-overlay" :class="{ show: joinId }">
      <div class="inner-delete" :class="{ popup: !finishJoin && joinId !== null }">
        <form @submit.prevent="join" id="form-join-room">
          <div class="title-info mb-3">Simulation Registration</div>
          <div class="label-popup mb-2">Choose Country</div>
          <select class="form-select mb-3" id="join-country" v-model="joinForm.country">
            <option :value="null">Country</option>
            <option v-for="(country, i) in countries" :key="i" :value="country.id">{{ country.name }}</option>
          </select>
          <div class="label-popup mb-2">Choose Class</div>
          <input type="text" class="form-control mb-4" id="join-class" v-model="joinForm.class" required>
          <div class="row g-2">
            <div class="col-6">
              <button type="button" class="btn btn-delete  btn-radius w-100" id="cancel-join" @click="joinId = null, finishJoin = false">Cancel</button>
            </div>
            <div class="col-6">
              <button type="submit" class="btn btn-orange btn-radius for-submit w-100" id="submit-join">Submit</button>
            </div>
          </div>
        </form>
      </div>

      <div class="box-thanks" :class="{ show: finishJoin }">
        <img src="/img/check.svg" class="img-fluid">
        <div class="ttl-oke">Registration Completed Successfully</div>
        <p>Please check ‘Simulation’ for the detail information</p>
        <button type="button" class="btn btn-orange btn-radius for-ok" id="ok" @click="successJoin">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomDetail",
  data() {
    return {
      id: null,
      subject: null,
      banner: null,
      issue: null,
      registration_date: null,
      simulation_date: null,
      overview: null,
      participants: [],
      model: null,
      overview_link: null,
      model_guide_link: null,
      relateds: [],
      joinId: null,
      finishJoin: false,
      joinForm: {
        country: null,
        class: null
      },
      countries: [],
      roomDetail: null
    }
  },
  mounted() {
    this.getRoomDetail()
    this.getRelatedRoom()
  },
  methods: {
    getRoomDetail() {
      this.$axios.get(`/master/room/overview_detail/${this.$route.params.slug}`)
        .then(response => {
          const room = response.data.data
          this.id = room.id
          this.banner = room.banner_url
          this.subject = room.subject_topic
          this.issue = room.the_issue
          this.registration_date = room.registration_date
          this.simulation_date = room.simulation_date
          this.overview = room.case_overview
          this.model = room.model_guide
          this.participants = room.role_list
          this.getRoomById()
        });
    },
    getRoomById() {
      this.$axios.get(`/master/room/${this.id}`)
        .then(response => {
          const room = response.data.data
          this.roomDetail = room
          this.countries = room.participants

          if (room.link_url.includes("youtube.com")) {
            const link1 = room.link_url.split('?')
            const params = new URLSearchParams(`?${link1[1]}`)
            for (const param of params) {
              if (param[0] === 'v') {
                this.overview_link = `https://www.youtube.com/embed/${param[1]}`
              }
            }
          }
          if (room.link_embed_url.includes("youtube.com")) {
            const link2 = room.link_embed_url.split('?')
            const params = new URLSearchParams(`?${link2[1]}`)
            for (const param of params) {
              if (param[0] === 'v') {
                this.model_guide_link = `https://www.youtube.com/embed/${param[1]}`
              }
            }
          }
        })
    },
    getRelatedRoom() {
      this.$axios.get('/master/room/more_cases')
        .then(response => {
          this.relateds = response.data.data.more_cases
        })
    },
    join() {
      this.$axios.post(`/simulation/student/join`, {
        room_id: this.id,
        requested_participant_id: this.joinForm.country,
        class: this.joinForm.class
      }).then(response => {
        console.log(response)
          this.finishJoin = true
        })
    },
    successJoin() {
      this.joinId = null 
      this.finishJoin = false
      this.$router.push('/simulation')
    }
  }
}
</script>

<style scoped>
.container-iframe {
  display: block;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 600px) {
  .responsive-iframe {
    width: 650px !important;
    height: 350px !important;  
  }
}

</style>
